<template>
  <Success />
</template>

<script>
import Success from "@/components/success/Success.vue";

export default {
  name: "success",
  components: {
    Success,
  },
};
</script>
